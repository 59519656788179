.telehealth-weird-arrow-alignment {
    display: flex;
    align-items: center;
}

.current-ux-video {
    width: 100%;
    max-width: 750px;
}

.final-left {
    width: 100%;
    max-width: 750px;
    margin: 10px;
}

.final-right {
    width: 100%;
    max-height: 525px;
    max-width: 262px;
    margin: 10px;
}

@media only screen and (max-width: 1100px) {
    .weird-arrow-container {
        display: none;
    }

    .weird-arrow-container2 {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 1100px) {
    .weird-arrow-container2 {
        display: none;
    }
}
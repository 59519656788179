.recommendation-card {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    max-width: 360px;
    cursor: pointer;
    height: 232px;
    margin: 14px;
    animation: fade-in;
    animation-duration: 0.6s;
    animation-timing-function: ease;
}

.rec-layer-1 {
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
}

.rec-title-name {
    margin-left: 16px;
}

.rec-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
}

.rec-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #727489;
}

.rec-body-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #1A1B1D;
    padding: 12px 20px;
}

.linked-in {
    float: right;
    margin: 20px;
}

.rec-img {
    border-radius: 50%;
    width: 56px;
}

@media only screen and (max-width: 750px) {
    .recommendation-card {
        margin: 14px 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
        position: relative;
        top: 40px;
    }
    to {
        opacity: 1;
        position: relative;
        top: 0;
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.home-root {
    max-width: 1200px;
    margin: auto;
}

.project-fade-in, .fade-in-fast {
    opacity: 0;
}

.project-fade-in.visible {
    animation: fadeInUp 1s forwards;
}

.fade-in-fast.visible {
    animation: fadeInUp 0.25s forwards;
}

.desc {
    text-align: center;
    font-family: Open Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 57.6px */
    padding: 30px 0;
    max-width: 865px;
    margin: auto;
}

.desc-highlight {
    color: #21618D;
}

.portfolio {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    margin-bottom: -3px;
    /*justify-content: space-between;*/
}

.child {
    flex: 1;
}

.full-width {
    flex: 2; /* This ensures that in rows with 2 children, the full-width child will occupy the full space */
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 26px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .desc {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding: 0 0 20px 0;
        margin-bottom: 10px;
        text-align: center;
        font-weight: 400;
    }
}
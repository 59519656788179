@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-left, .fade-in-delayed-left, .fade-in-right, .fade-in-delayed-right{
    opacity: 0;
}

.fade-in-left.visible {
    animation: fadeInLeft 0.5s forwards;
}

.fade-in-delayed-left.visible {
    animation: fadeInLeft 0.5s forwards;
    animation-delay: 0.25s;
}

.fade-in-right.visible {
    animation: fadeInRight 0.5s forwards;
}

.fade-in-delayed-right.visible {
    animation: fadeInRight 0.5s forwards;
    animation-delay: 0.25s;
}

.about-title {
    text-align: left;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #19191A;
}

.first-section {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.below-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #008286;
    margin-top: 20px;
    margin-bottom: 48px;
}

.about-me {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #19191A;
    max-width: 600px
}

.second-section {
    margin: 100px auto 96px auto;
    max-width: 1200px;
}

.perspective-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #19191A;
    margin-bottom: 40px;
    margin-top: 40px;
}

.third-section {
    display: flex;
    justify-content: flex-start;
    max-width: 1200px;
    margin: auto;
    padding-top: 96px;
}

.s3-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #19191A;
}

.s3-second-column {
    margin-left: 72px;
}

.how-designer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #19191A;
    margin-top: 40px;
}

.recommendation-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.profile-pic {
    margin-left: 40px;
    width: 100%;
    max-width: 433px;
}

@media only screen and (max-width: 1080px) {
    .profile-pic, .bottom-image {
        max-width: 300px;
    }
}

@media only screen and (max-width: 950px) {
    .profile-pic, .bottom-image {
        max-width: 250px;
    }
}

/*mobile*/
@media only screen and (max-width: 768px) {
    .profile-pic {
        margin: auto;
        padding-bottom: 20px;
    }

    .bottom-image {
        margin-bottom: 20px;
    }

    .first-section {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .third-section {
        flex-wrap: wrap;
        justify-content: center;
    }

    .s3-second-column {
        margin-left: 0;
    }

    .perspective-title, .about-title, .s3-title {
        font-size: 24px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .perspective-title, .bottom-image {
        margin-top: 60px;
    }
    .perspective-title {
        margin-top: 80px;
    }

    .below-title {
        font-size: 16px;
        line-height: 18px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .about-me {
        font-size: 15px;
        line-height: 20px;
    }

    .first-section, .third-section {
        justify-content: left;
    }

    .third-section {
        padding-top: 30px;
    }

    .how-designer {
        margin-top: 10px;
        font-size: 15px;
        line-height: 20px;
    }
}
.portfolio-item {
    cursor: pointer;
    animation: fade-in;
    animation-duration: 0.6s;
    animation-timing-function: ease;
}
.portfolio-image {
    width: 100%;
}

.blur {
    filter: blur(4px);
}

@keyframes fade-in {
    from {
        opacity: 0;
        position: relative;
        top: 40px;
    }
    to {
        opacity: 1;
        position: relative;
        top: 0;
    }
}
.lets-talk {
    color: #000;
    font-family: Open Sans;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.contact-desc {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    max-width: 616px;
    margin: 20px auto;
}

.custom-button {
    color: white;
    border-radius: 100px;
    background: #008286;
    display: flex;
    padding: 16px 48px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 333px;
    margin: 64px auto 144px auto;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
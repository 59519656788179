.header {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.header-logo {
    cursor: pointer;
    display: inline-block;
    max-width: 150px;
}

.header-left {
    display: inline-block;
    cursor: pointer;
}

.header-side {
    display: flex;
    align-items: center;
}

.header-side-item {
    display: inline-block;
    padding: 0 36px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #19191A;
    cursor: pointer;
}

.header-side-item.white {
    color: white;
}

.header-side-item.selected {
    color: #06C2C9;
}

.header-side-item.selected.white {
    color: #06C2C9;
}

@media only screen and (max-width: 832px) {
    .header {
        padding: 20px 0;
    }

    .header-name {
        font-size: 18px;
        line-height: 27px;
    }

    .header-below-name {
        font-size: 13px;
        line-height: 18px;
    }
    .header-logo {
        max-width: 50px;
    }

    .header-right-item {
        padding: 0 18px;
    }
}

.logo-container-small, .header-small {
    display: none;
}

@media only screen and (max-width: 600px) {
    .header {
        display: none;
    }

    .logo-container-small {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .header-small {
        display: flex;
        justify-content: space-evenly;
        padding: 20px 0;
    }

    .header-side-item {
        font-size: 12px;
    }

    .header-logo {
        max-width: 85px;
    }

    .header-side-item {
        padding: 0;
    }

    .header {
        justify-content: space-evenly;
        width: 100%;
    }

    .header-side {
        justify-content: space-evenly;
        width: 100%;
    }
}
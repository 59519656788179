.footer {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 40px;
}

.footer-item {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #19191A;
    margin: 0 14px;
    cursor: pointer;
}

.footer-item.selected {
    color: #06C2C9;
}

.footer-slash {
    margin: 0 14px;
}

@media only screen and (max-width: 600px) {
    .footer-item {
        font-size: 12px;
        margin: 0 7px;
    }
}
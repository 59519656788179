.case-study-root {
    padding-top: 20px;
    max-width: 1200px;
    margin: auto;
}

.case-study-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #21618D;
    margin-bottom: 20px;
}

.case-study-h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #19191A;
    margin-top: 40px;
}

.case-study-h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #6D6F72;
    margin-top: 32px;
}

.case-study-paragraph {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #19191A;
    padding-top: 16px;
    padding-bottom: 32px;
}

ul {
    margin-top: 0;
    margin-bottom: 0;
}

.cs-gray-box {
    background: #F4F4F7;
    padding: 52px;
    margin: 16px 0;
}

.pic-to-pic {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.no-vertical-center {
    align-items: flex-start;
}

.cs-pic {
    width: 100%;
    margin-top: 20px;
}

.arrow {
    width: 21px;
    margin: 22px;
}

.solo-pic {
    max-width: 566px;
    margin: auto;
}

.video-side-by-side {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.h3-more-padding {
    margin-top: 50px;
}

.top-alignment {
    align-items: flex-start;
}

.pic-side-by-side {
    display: flex;
    margin-top: 16px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.x3-pic-side-by-side {
    display: flex;
}

.centered-pic-3x {
    flex: 1;  /* this means each item will grow and shrink equally */
    margin: 0 16px;
}

.pic-side-by-side-no-side-margin {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    flex-wrap: wrap;
}

.centered-pic-container {
    display: flex;
    justify-content: center;
    max-width: 700px;
    margin: auto;
}

.centered-pic {
    max-width: 100%;
}

.left-aligned-pic-container {
    max-width: 702px;
}

.left-aligned-pic {
    max-width: 100%;
}

.gif {
    max-width: 100%;
    margin-right: 64px;
}

.gif-sbs {
    max-width: 375px;
}

.cs-pic-container-sbs {
    display: inline-block;
    max-width: 515px;
}

.gif-container {
    max-width: 375px;
    margin-bottom: 40px;
}

video {
    margin-top: 40px;
}

.case-study-ltr-arrow-left {
    color: #19191A;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
}

.case-study-ltr-arrow-row {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.case-study-ltr-arrow {
    margin: 0 16px;
}

.case-study-ltr-arrow-right {
    color: #19191A;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
}

.case-study-pain-point-1 {
    display: inline;
    color: #21618D;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
}


.case-study-pain-point-2 {
    display: inline;
    color: #19191A;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;

}

@media only screen and (max-width: 1100px) {
    .pic-to-pic {
        display: block;
        margin: auto;
    }

    .cs-pic-container {
        max-width: 700px;
        margin: auto;
    }

    .cs-pic-arrow-container {
        display: flex;
        justify-content: center;
    }

    .arrow {
        transform: rotate(90deg);
    }
}

.case-study-pain-point-arrows-container {
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.case-study-pain-point {
    margin-bottom: 24px;
    margin-top: 40px;
}

.case-study-pain-point-desc {
    margin-bottom: 32px;
}

.case-study-pain-point-left-right {
    flex: 1; /* shorthand for flex-grow: 1; flex-shrink: 1; flex-basis: 0; */
}

.case-study-pain-point-number {
    color: #21618D;
}

.case-study-pain-point-arrow {
    margin: 0 36px;
}

.order-history-video {
    width: 100%;
}

@media only screen and (max-width: 650px) {
    .cs-gray-box {
        padding: 24px;
        margin: 16px 0;
    }

    .cs-pic-container-sbs {
        margin: 0;
    }
}

@media only screen and (max-width: 850px) {
    .gif-sbs {
        margin: 20px auto;
    }
}
@media only screen and (max-width: 500px) {
    .case-study-title {
        font-size: 24px;
        line-height: 30px;
    }

    .case-study-h2 {
        font-size: 21px;
        line-height: 30px;
        margin-top: 20px;
    }

    .case-study-h3 {
        font-size: 18px;
        line-height: 32px;
        margin-top: 24px;
    }

    .case-study-paragraph {
        font-size: 14px;
        line-height: 24px;
        padding-top: 8px;
        padding-bottom: 16px;
    }


    .gif {
        margin-right: 0;
    }

    .gif-container {
        max-width: 300px;
        margin: 40px auto;
    }

    .gif-sbs {
        max-width: 300px;
    }

    .case-study-ltr-arrow-left {
        font-size: 18px;
    }

    .case-study-ltr-arrow-right, .case-study-pain-point-left-right {
        font-size: 14px;
    }

    .case-study-pain-point-arrows-container {
        display: flex;
        flex-direction: column;
    }

    .case-study-pain-point-arrow-img {
        transform: rotate(90deg);
    }

    .case-study-ltr-arrow, .case-study-pain-point-arrow {
        display: flex;
        justify-content: center;
    }
}